@import "https://fonts.googleapis.com/css?family=Roboto:300,400,500";

// variables
$background: #f5f6fa;
$color: #80939c;
$field-width: 360px;
$btn-padding-large: 22px;
$btn-padding-small: 18px;
$btn-width: $field-width / 3;
$button-bg-color: #7f8ff4;
$button-text-color: #fff;
$box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
$list-bg-color: #e9e9e9;
$color-unchecked: #b2b3b1;
$color-checked: #78b13f;
$icon-height: 24px;
$icon-width: 24px;
$icon-thickness: 4px;

// Let's have some basics to be ok for most devices
html, body, #root {
  height: 100%
}

body {
  margin: 0 auto;
  max-width: 50em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.5;
  padding: 0 1em;
  color: $color;
  background-color: $background;
}

h2 {
  margin-top: 0px;
  padding-top: 0px;
}

h1 {
  margin-top: 0.25em;
  padding-top: 0.25em;
}

h1,
h2,
strong {
  color: #423a3a;
}

p,
ul,
ol {
  margin: 0.5em 0;
  padding: 0;
  font-family: sans-serif;
}

figure {
  margin: 0px;
}

.logo {
  background: no-repeat url("https://avatars.githubusercontent.com/u/18654552?v=4");
  background-size: cover;
  height: 150px;
  margin: 0;
  width: 150px;
  position: relative;
  border-radius: 50%;
}

.app {
  padding-top: 1em;
  max-height: 100%;
  height: -webkit-fill-available;
}

header {
  position: relative;

  button {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.tasks {
  margin: 0em 0.5em;

  &__form {
    position: relative;
    margin: 0 auto;
    padding-right: $btn-width + $btn-padding-small;
    max-width: 40ch;

    &_field {
      width: 95%;
      background: #fff;
      color: $color;
      font: inherit;
      box-shadow: $box-shadow;
      border: 0;
      border-radius: 8px;
      outline: 0;
      padding-top: $btn-padding-large;
      padding-bottom: $btn-padding-large;
      padding-left: $btn-padding-small;
      padding-right: $btn-width - 2 * $btn-padding-small;

      &::placeholder {
        opacity: 0.4;
      }
    }

    &_btn {
      display: inline-block;
      background: transparent;
      font: inherit;
      border: 0;
      outline: 0;
      transition: all 200ms ease-in;
      background: $button-bg-color;
      color: $button-text-color;
      box-shadow: $box-shadow;
      border-radius: 4px;
      padding: $btn-padding-large / 2 $btn-padding-large;
      margin-left: -$btn-width + $btn-padding-large;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0%, -50%);
      width: $btn-width;

      &:active {
        background: $button-bg-color;
        box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.2);
      }

      &:hover {
        background: darken($button-bg-color, 4%);
      }
    }
  }

  &__list {
    padding: 2em;
    margin: 3em 0em;
    background-color: $list-bg-color;
    border-radius: 8px;

    &-empty {
      text-align: center;
    }
  }
}

.task {
  background-color: lighten($list-bg-color, 10%);
  border-radius: 4px;
  padding: 0.5em;
  box-shadow: $box-shadow;
  list-style: none;
  text-align: left;
  margin: 1em 0em;
  position: relative;

  display: flex;
  flex-direction: row;
  gap: 0.2em;
  justify-content: flex-start;
  align-items: center;

  &__form {
    width: 80%;
    position: relative;
    margin: 0 auto;
    padding-right: 26px;

    &_field {
      background: #fff;
      color: $color;
      font: inherit;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 8px;
      outline: 0;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 8px;
      padding-right: $icon-width + 26px;
      width: calc(100% - #{$icon-width} - 12px);
    }

    &_btn {
      display: block;
      position: absolute;
      top: 50%;
      right: 0px;
      left: calc(100% - #{$icon-width});
      height: $icon-height;
      padding: 0;
      transform: translate(-50%, -50%);
      border: 0;
      outline: 0;
      transition: all 200ms ease-in;
      background: transparent;

      & .icon-end {
        padding: 0;
      }

    }
  }
}

// Helpers
.uppercase {
  text-transform: uppercase;
}

.pointer {
  &:hover {
    cursor: pointer;
  }
}

.pushed {
  &-right {
    margin-left: auto;
  }
}

.striked {
  text-decoration: line-through;
}

.faded {
  opacity: 0.4;
}

.color {
  &-red {
    color: #d34141;
  }

  &-green {
    color: $color-checked;
  }

  &-grey {
    color: $color-unchecked;
  }
}

.icon {
  width: $icon-width;
  height: $icon-height;
  margin: 0;
  padding-right: 0.2em;
  border-right: 2px solid $list-bg-color;

  &-end {
    border-right: none;
    padding-right: 0em;
    padding-left: 0.2em;
  }
}

.flex {
  display: flex;
}